import React from "react";
import styles from "../DateSelect.module.scss";

const DateNavigator = ({ date, prevWeek, nextWeek, isPrevWeekDisabled, selectedDate }) => {
    const isPrevWeekBtnAllowed = () => {
        if (!isPrevWeekDisabled) prevWeek();
    }
    const prevWeekStyle = isPrevWeekDisabled ? styles.disabled : "";
    
    return (
        <div className={styles.topBar}>
            <div className={`${styles.dateTitle} ${ selectedDate ? styles.selected : styles.notSelected }`}>
                {date?.format('ddd, MMMM D, YYYY')}
            </div>
            <div className={styles.dateNav}>
                <div onClick={isPrevWeekBtnAllowed} className={prevWeekStyle}>
                    <i className={`bi bi-chevron-left ${isPrevWeekDisabled ? styles.iconDisabled : ''}`}></i>
                </div>
                <div onClick={nextWeek}>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div>
        </div>
    )
}

export default DateNavigator;