import React,{useEffect, useState} from 'react'
import { useSelector } from "react-redux";
import Cart from '../Cart/Cart';
import WithOutsideClick from "@helpers/clickOutsideWrapper";
import { steps } from "@helpers/steps"
import './Header.scss'

const CartWrapper = WithOutsideClick(Cart);

const Header = ({ goBack, currentStep, enabledServices }) => {
    const showCart = currentStep?.key !== steps.SUMMARY.key;
    const [showItemsPane, setShowItemsPane] = useState(false);
    const [isEmbedded, setIsEmbedded] = useState(true);
    const account = useSelector(state => state.account);
    const fromGLS = /[?&]fromgls=true(&|$)/.test(window.location.href);

    useEffect(() => {
        setIsEmbedded(window.location !== window.parent.location)
    }, [])

    const openItemsPane = (open=true) => {
        setShowItemsPane(open);
    }

    return (
        <div className="header">
            <div className={`header-inner ${!account.accountLogo || isEmbedded ? 'no-logo' : ''}`}>
                <div className="left-side">
                    {account.accountLogo && account.uselogo && !isEmbedded ? <img
                        className="account-logo"
                        alt={account.title}
                        src={account.accountLogo}
                    /> : ""}
                    <div className="content">
                        <div className="account-name">{fromGLS ? account.account_name : account.title}</div>
                        <div className="account-slogan">{account.description}</div>
                        {fromGLS && <div className="account-adress">{account.geo}</div>}
                    </div>
                </div>
                {showCart &&
                <CartWrapper
                    enabledServices={enabledServices}
                    goBack={goBack}
                    toggle={() => openItemsPane(false)}
                    showItemsPane={showItemsPane}
                    openItemsPane={openItemsPane}
                />}
            </div>
        </div>
    )

}

export default Header;