import React, { useState, useEffect } from "react";
import moment from "moment";
import DateDay from "./sections/DateDay";
import TimeSlots from "./sections/TimeSlots";
import DateNavigator from "./sections/DateNavigator";
import StepTitle from "@components/StepTitle/StepTitle";
import { updateForm } from "@actions/formActions"
import { useDispatch, useSelector } from "react-redux";
import { createAvailableSlots } from "@helpers/calendarHelper";
import styles from "./DateSelect.module.scss";

const DateSelect = ({
    takenSlots,
    workingTimes,
    slotLength,
    scheduleOffset,
    availableBased,
    availableJobsPerSlot,
    totalTechs,
    goToNextStep,
    outCalendarDays,
    loadSlots
}) => {
    const DATE_RANGE = 25;
    const form = useSelector(state => state.form);
    const [dateNavDay, setDateNavDay] = useState(form.dateNavDay ? form.dateNavDay : moment());
    const [weekDays, setWeekDays] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [availableSlotsPerWeek, setAvailableSlotsPerWeek] = useState({})
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment().add(DATE_RANGE, 'days'))
    const dispatch = useDispatch();
    const formDate = form.date ? moment(form.date).add(form.time || 0, "hour") : null;
    const selectedDate = formDate ? formDate.toDate() : null;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        setCurrentWeekDays()
    }, [dateNavDay, startDate, endDate])
    
    useEffect(() => {
        getSlotsForWeek();
    }, [weekDays])

    useEffect(() => {
        getSlots()
    }, [form])

    const getSlots = () => {
        let _availableSlots = createAvailableSlots(
            selectedDate,
            takenSlots,
            workingTimes,
            slotLength,
            scheduleOffset,
            availableBased,
            availableJobsPerSlot,
            totalTechs
        )
        setAvailableSlots(_availableSlots)
    }

    const getSlotsForWeek = () => {
        let _availableSlotsPerWeek = {};
        weekDays.forEach((dateInWeek) => {
            const date = dateInWeek?.toDate();
            let _availableSlots = createAvailableSlots(
                date,
                takenSlots,
                workingTimes,
                slotLength,
                scheduleOffset,
                availableBased,
                availableJobsPerSlot,
                totalTechs
            )
            _availableSlotsPerWeek[date] = _availableSlots;
        });

        setAvailableSlotsPerWeek(_availableSlotsPerWeek);
    }

    const setCurrentWeekDays = () => {
        const weekStart = dateNavDay.startOf('day');
        const days = [];
        for (let i = 0; i <= 6; i++) {
            days.push(moment(weekStart).add(i, 'days'));
        }
        setWeekDays(days);
    }

    const isAvailable = (day) => {
        if (availableSlotsPerWeek[day.toDate()] && !availableSlotsPerWeek[day.toDate()]?.length) {
            return false;
        }
        if (day.isBefore(new Date(), "day")) {
            return false;
        }
        // patch because of mon == 0 in our backend
        const weekDay = day.weekday() === 0 ? 6 : day.weekday() - 1
        if (!workingTimes[weekDay]) {
            return false;
        }
        if ((outCalendarDays || []).includes(day.format("DD-MM-YY"))) {
            return false;
        }
        return true;
    }

    const renderDaySelect = () => {
        return (
            <div className={styles.days}>
                {
                    weekDays.map((day, index) => {
                        const available = isAvailable(day);
                        return <DateDay
                            available={available}
                            key={index}
                            day={day}
                            onDone={dateSelected}
                            selected={selectedDate}
                        />
                    })
                }
            </div>
        )
    }

    const checkIfException = (rangeStartDate, rangeEndDate) => {
        if (startDate.isBetween(rangeStartDate, rangeEndDate, null, '[]') ||
            endDate.isBetween(rangeStartDate, rangeEndDate, null, '[]')) {
            const _startDate = moment.max([moment(), moment(rangeStartDate).clone().subtract(DATE_RANGE, "days")])
            const _endDate = moment(rangeEndDate).clone().add(DATE_RANGE, "days");
            setStartDate(_startDate)
            setEndDate(_endDate)
            loadSlots(_startDate, _endDate);
        }
    }

    const prevWeek = () => {
        const _date = moment(dateNavDay).clone().subtract(1, 'week');
        setDateNavDay(_date);
        checkIfException(_date, dateNavDay.subtract(1, 'day'))
    }

    const nextWeek = () => {
        const _date = moment(dateNavDay).clone().add(1, 'week');
        setDateNavDay(_date);
        checkIfException(_date, moment(_date).clone().add(1, 'week').subtract(1, 'day'))
    }

    const dateSelected = date => {
        dispatch(updateForm({ type: 'date', data: date.toDate() }))
        dispatch(updateForm({ type: 'time', data: "" }))
        dispatch(updateForm({ type: 'dateNavDay', data: dateNavDay }))
        getSlots()
    }

    const getDateToDisplay = () => {
        let _selectedDate = selectedDate ? moment(selectedDate) : moment();
        return _selectedDate.isBetween(dateNavDay, dateNavDay.clone().add(6, "days"), null, '[]') ? _selectedDate : dateNavDay;
    }

    return (
        <div>
            <StepTitle title="When works best for you?" />
            <div className={styles.container}>
                <DateNavigator
                    date={getDateToDisplay()}
                    prevWeek={prevWeek}
                    nextWeek={nextWeek}
                    isPrevWeekDisabled={dateNavDay.week() === moment().week()}
                    selectedDate = { selectedDate }
                />
                {renderDaySelect()}
                <TimeSlots
                    availableSlots={availableSlots}
                    selected={selectedDate}
                    slotLength={slotLength}
                    goToNextStep={goToNextStep}
                />
            </div>
        </div>

    )
}

export default DateSelect;