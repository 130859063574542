import React from 'react';
import './StepTitle.scss'

const StepTitle = (props) => {
    return (
        <div className="step-title">
            <div className={`title`}>
                {props.title}
            </div>
        </div>
    )
}

export default StepTitle;


