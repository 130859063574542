import React from "react";
import styles from "./StepsBar.module.scss"
import { mobileMode } from "@helpers/mobileHelper"

const Step = ({ isActive, label, isDone }) => {
    const active = isActive ? styles.active : ""
    const done = isDone ? styles.done : ""
    const showLabel = mobileMode() ? (isActive ? true : false) : true;
    return (
        <div className={`${styles.step} ${active} ${done}`}>
            <div className={styles.number}>
                <div className={styles.connector}></div>
                <div className={styles.dot}>
                    <i className="bi bi-check" />
                </div>
                <div className={styles.connector}></div>
            </div>
            {showLabel && <div className={styles.label}>{label}</div>}

        </div>
    )
}

export default Step;