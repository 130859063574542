import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { formatCurrency, formatPercentage } from "../../helpers/numbers";
import LineItem from "./LineItem";
import styles from "./ItemsPane.module.scss"

const ItemsPane = ({ isOpen, close, goBack }) => {

    const services = useSelector(state => state.services);
    const servicesTotals = useSelector(state => state.servicesTotals);
    const serviceFee = useSelector(state => state.account.paymentSettings.serviceFeeSettings ?? null);
    const taxRate = useSelector(state => state.account.paymentSettings?.tax);
    const lineItems = services.filter(service => service.quantity > 0);
    const isServiceFee = serviceFee && serviceFee.value;
    const serviceFeeAmount = isServiceFee ? servicesTotals.totalAmount * (serviceFee.rate / 100) : 0;

    const NoItems = () => {
        if (servicesTotals.numOfServices > 0) return null
        return (
            <div className={styles.noItems}>
                <span>No items have been added yet</span>
            </div>
        )
    }

    const renderItems = () => {
        if (servicesTotals.numOfServices == 0) return null
        return (
            <div className={styles.invoice}>
                <div className={styles.items}>
                    <div className={styles.title}>Service items</div>
                    {
                        lineItems.map((item, i) => <LineItem key={i} item={item} goBackToFirstStep={goBack} closeItemsPane={close} />)
                    }
                </div>
                {servicesTotals.totalTax ?
                    <>
                        <div className={`${styles.totals} ${styles.subTotal}`}>
                            <div>SUB TOTAL</div>
                            <div>{formatCurrency(servicesTotals.totalAmount)}</div>
                        </div>
                        <div className={styles.paymentSection}>
                            <div className={styles.paymentItems}>
                                <div className={styles.paymentItem}>
                                    <div className={styles.name}>{`Tax (${formatPercentage(taxRate)})`}</div>
                                    <div className={styles.price}>{formatCurrency(servicesTotals.totalTax)}</div>
                                </div>
                            </div>
                        </div>
                    </> : null
                }
                {isServiceFee ?
                    <>
                        <hr />
                        <div className={styles.paymentSection}>
                            <div className={styles.paymentItems}>
                                <div className={styles.paymentItem}>
                                    <div className={styles.name}>{`SERVICE FEE (${formatPercentage(serviceFee.rate)})`}</div>
                                    <div className={styles.price}>{formatCurrency(serviceFeeAmount)}</div>
                                </div>
                            </div>
                        </div>
                    </> : null
                }
                <div className={`${styles.totals} ${styles.total}`}>
                    <div>TOTAL</div>
                    <div>{formatCurrency(servicesTotals.totalAmount + servicesTotals.totalTax + serviceFeeAmount)}</div>
                </div>
            </div>
        )
    }

    return (
        <div className={`${styles.pane} ${(isOpen ? styles.open : "")}`} data-dont_trigger_outside_click={true}>
            <div className={styles.heading}>
                <div className={styles.close} onClick={close}>
                    <i className="bi bi-x-lg"></i>
                </div>
                <div className={styles.title}>Your order</div>
            </div>
            {renderItems()}
            <NoItems />
        </div>
    )
}

export default ItemsPane;