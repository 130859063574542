import React, { useEffect, useState } from 'react';
import './Service.scss'
import serviceNoImage from "@assets/images/noImage.svg";
import Tooltip from 'react-tooltip-lite';
import { mobileMode, isMobileDevice } from "@helpers/mobileHelper";
import ServiceDetailsModal from '../../ServiceDetailsModal/ServiceDetailsModal';
import info from '@assets/images/info.svg';
import minusIcon from '@assets/images/minusIcon.svg';
import plusIcon from '@assets/images/plusIcon.svg';
import minusNoQuantityIcon from '@assets/images/minusNoQuantityIcon.svg';

const MAX_MOBILE_SIZE = 600;

const Service = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mobileSize, setMobileSize] = useState(window.innerWidth <= MAX_MOBILE_SIZE)
    const maxItemNameLength = 25;
    const maxItemDescLength = 48;

    const handleResize = () => {
        setMobileSize(window.innerWidth < MAX_MOBILE_SIZE);
    }

    

    useEffect(()=>{
        window.addEventListener("resize", handleResize, false);
    }, [])

    const renderItemName = () => {
        return props.data.item_name.length > maxItemNameLength ? `${props.data.item_name.substring(0, 25)}...` : props.data.item_name
    }

    const renderDescription = () => {
        return props.data.long_desc.length > maxItemDescLength ? `${props.data.long_desc.substring(0, 55)}...` : props.data.long_desc
    }

   


    return (
        <div className={`service-wrap`}>
            <div className="service">
                <div onClick={props.openServiceDetails} className="top">
                    <div className="image">
                        <div className="price">${props.data.booking_price}</div>
                        {!props.data.img_url && <div className="service-no-image-wrapper">
                            <img alt='no service' className="service-no-image" src={serviceNoImage}/>
                        </div>}
                        {props.data.img_url && <div className='img-wrap'>
                            <img alt='service' src={props.data.img_url}/>
                        </div>}
                        {/* <div onClick={props.openDetails} className="info">
                            <img className="info-img" src = {info}/>
                        </div> */}
                    </div>
                    <div className="details-wrapper">
                        <div className="details-quantity">
                            <div className="details">
                                <div className="service-name">{renderItemName()}</div>
                                <div className="description">{renderDescription()}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="info-wrapper" onClick={props.openDetails}>
                    <div className="quantity-wrapper" onClick={ (e) => e.stopPropagation() }>{/* eslint-disable */}
                        <div onClick={() => props.data.quantity === 0 ? false : props.controlsClick('remove') }
                            className={`quantity-circle remove ${!props.data.quantity ? "noQuantityBtn" : ""}`}><img src={props.data.quantity ? minusIcon : minusNoQuantityIcon} alt='icon' />
                        </div>
                        <div className={`quantity ${!props.data.quantity ? "no-quantity" : ""}`}>{props.data.quantity}</div>
                        <div onClick={() => props.controlsClick('add') }
                         className="quantity-circle add"><img src={plusIcon} alt='icon'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service;