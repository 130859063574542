import React from 'react';

const WithOutsideClick = (WrappedComponent, styles) =>{
    return class extends React.Component{
        constructor(props){
            super(props);
            this.handleClick = this.handleClick.bind(this);
        }

        handleClick(e){
             // ignore clicks on the component itself
            if(this.node == null) return;
            if (this.node.contains(e.target) || e.target?.hasAttribute('data-dont_trigger_outside_click') ||
                e.target?.firstElementChild?.hasAttribute('data-dont_trigger_outside_click')) {
                return;
            }
            this.props.toggle();
        }
        

        componentDidMount(){
            document.addEventListener('click', this.handleClick, false);
        }
        componentWillUnmount(){
            document.removeEventListener('click', this.handleClick, false);
        }

        render(){
            return (
                <span style={{...styles}} ref={node => { this.node = node; }} data-testid="with-click-outside" >
                    <WrappedComponent {...this.props}/>
                </span>
            )
        }
    }
} 

export default WithOutsideClick;