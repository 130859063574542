import React from 'react';
import './ServicesList.scss'
import Service from './Service/Service'
import {connect} from "react-redux";
import {updateServiceQuantity, updateServicesTotalAmount} from '@actions/servicesActions';
import StepTitle from "@components/StepTitle/StepTitle";

const ServicesList = (props) => {

    const colorPalette = [
        "#50D58C",
        "#6AA8EE",
        "#D574E4",
        "#FBAB33",
        "#FF6F64",
        "#C1A87D",
        "#9EA6AA",
        "#3B4C53",
        "#50D58C",
        "#6AA8EE",
        "#D574E4",
        "#FBAB33",
        "#FF6F64",
        "#C1A87D",
        "#9EA6AA",
        "#3B4C53",
        "#50D58C",
        "#6AA8EE",
        "#D574E4",
        "#FBAB33",
        "#FF6F64",
        "#C1A87D",
        "#9EA6AA",
        "#3B4C53",
    ]

    const renderServices = () => {
        return props.services.map((service, index) => {
            if (service.status == 0) {
                return null
            }
            return <Service
                controlsClick={(action) => changeServiceQuantity(service, action)}
                key={service.p_id}
                data={service}
                separatorColor={colorPalette[index]}
                openServiceDetails ={() => props.openServiceDetails(service)}
            />
        })
    }

    const changeServiceQuantity = (service, action) => {
        props.changeServiceQuantity({serviceId: service.p_id, action})
        props.updateServicesTotalAmount(
            {
                price: service.booking_price,
                taxable: service.taxable,
                taxRate: props.account.paymentSettings?.tax,
                action
            })
    }

    return (
        <div className={"services-list-wrapper"}>
            <StepTitle title="What will you need for this service?"/>
            <div className="services-list">
                {renderServices()}
            </div>
        </div>

    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        account: state.account,
        services: state.services
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeServiceQuantity: (payload) => dispatch(updateServiceQuantity(payload)),
        updateServicesTotalAmount: (payload) => dispatch(updateServicesTotalAmount(payload))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServicesList);
