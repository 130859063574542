import React, {useState, useImperativeHandle, forwardRef, useEffect} from 'react';
import Tooltip from 'react-tooltip-lite';
import './Payment.scss'

import * as dayjs from 'dayjs'
import {connect} from "react-redux";
import config from './../../config';
import {trackEventToAll} from "@helpers/eventsHelper";
import {getPaymentAmount,getPaymentAmountWithTax,getDepositAmount,} from "@helpers/totalAmountHelper";

import Cards from 'react-credit-cards';
import "react-credit-cards/es/styles-compiled.css";
import valid from "card-validator"
import {loadStripe} from '@stripe/stripe-js/pure';
import {
    Elements, ElementsConsumer, CardCvcElement, CardExpiryElement, CardNumberElement
} from '@stripe/react-stripe-js';
import {setIsLoading} from "@actions/isLoadingActions";
import {getWePayToken, saveBooking, updateForm, saveClientCardToken, getSetupIntent, createClient} from "@actions/formActions";

import Grid from '@components/Grid/Grid';
import {TextField} from "@material-ui/core";
import StepTitle from "@components/StepTitle/StepTitle";
import TotalDeposit from "./TotalDeposit/TotalDeposit";
import Background from "@assets/images/paymentBackground.png"
import BackgroundMobile from "@assets/images/paymentBackgroundMobile.png"
import CreditCard from "@assets/images/creditCard.svg"
import alertIcon from "@assets/images/alertIcon.svg";
import questionMark from '@assets/images/questionMark.svg';
import creditCardSVG from "@assets/images/creditCardTemplate.svg";
import ReCAPTCHA from 'react-google-recaptcha';

const MAX_MOBILE_SIZE = 1300;

const Payment = forwardRef((props, ref) => {
    const [stripePromise, setStripePromise] = useState(null)
    const [paymentGateway, setPaymentGateway] = useState(null)
    const [captchaToken, setCaptchaToken] = useState(null);
    const [mobileSize, setMobileSize] = useState(window.innerWidth <= MAX_MOBILE_SIZE)
    const displayCaptcha = props.account.createAutoJobOrLead ? true : false;
    let elementsObj = null;
    let stripeObj = null;
    const style = {
        base: {
            color: '#3B4C53',
            fontWeight: '400',
            fontFamily: '"Roboto", Sans-serif',
            fontSize: '16px',
            '::placeholder': {
                color: '#FFF',
            },
        },
    };

    const handleResize = () => {
        setMobileSize(window.innerWidth <= MAX_MOBILE_SIZE);
    }


    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        setStripePromise(loadStripe(config.STRIPE_CUSTOM_CONNECT_ACCESS_TOKEN))
        setPaymentGateway(props.account.paymentSettings?.gateway === 'workiz-stripe' ? 'workiz-stripe' : 'workiz')
    }, [])


    const confirmSetupIntent = async (clientId) => {
        const setupData = await props.getSetupIntent({clientId: clientId, accountId:  props.account.accountId});
        const result = await stripeObj.confirmCardSetup(setupData.setup_intent.client_secret, {
            payment_method: {
                card: elementsObj.getElement('cardNumber'),
                billing_details: {
                    name: form.name,
                    email: props.form.email,
                    address: {
                        country: props.form.country
                    },
                }
            }
        });
        return result
    }

    useEffect(() => {
        if(displayCaptcha) {
            isFormValid();
        }
    }, [captchaToken]);


    useImperativeHandle(ref, () => ({
        async next() {
            if(props.isLoading || (displayCaptcha && !captchaToken)){
                return false;
            }

            let {validations} = form
            validations.number.error = ''
            validations.expiration.error = ''
            validations.cvc.error = ''

            let jobDate = dayjs(new Date(props.form.date)).hour(0).minute(0).add(props.form.time, 'hours')
            let jobEndDate = dayjs(new Date(props.form.date)).hour(0).minute(0).add(props.form.time, 'hours').add(props.account.slotLength, 'hours')
            const firstName = props.form.first_name
            const lastName = props.form.last_name;
            const services = props.services.filter(service => service.quantity > 0)
            const paymentAmount = props.account.paymentSettings?.tax ? getPaymentAmountWithTax() : getPaymentAmount()

            let cardToken = ''
            let cvv = ''
            let expMonth = ''
            let expYear = ''
            let cardType = ''
            let lastFourDigits = ''

            const paymentPayload = {
                stripe_account_id: props.account.paymentSettings?.stripe_account_id,
                holder_name: form.name,
                email: props.form.email,
                cc_number: form.number,
                expiration_month: form.expiration.substring(0, 2),
                expiration_year: form.expiration.substring(3),
                cvc: form.cvc,
                card_type: form.cardType,
                address: {
                    country: props.form.country,
                    postal_code: props.form.zip
                }
            }

            const bookingPayload = {
                amount: paymentAmount.toFixed(2),
                deposit: getDepositAmount().toFixed(2),
                ac: props.account.ac,
                ad_group: props.account.adGroup,
                ad_group_name: props.account.adGroupName,
                first_name: firstName,
                last_name: lastName,
                email: props.form.email,
                description: props.form.description,
                account_id : props.account.accountId,
                address: props.form.address,
                city: props.form.city,
                zip: props.form.zip,
                state: props.form.state,
                country: props.form.country,
                phone: props.form.phone,
                job_date: jobDate.format("MM/DD/YYYY H:mm"),
                job_end_date: jobEndDate.format("MM/DD/YYYY H:mm"),
                services: services,
                job_type: props.selectedJobType,
                job_type_name: props.jobTypes.find(jobType => jobType?.id === props.selectedJobType)?.type_name || "",
                hasPayment: true,
                customFields: props.form.customFields,
                customFieldsKeys: props.form.customFieldsKeys,
                files: props.form.files,
            }

            props.setIsLoading(true)

            expMonth = form.expiration.substring(0, 2)
            expYear = form.expiration.substring(3)
            cardType = form.cardType
            lastFourDigits = "" + form.number.substr(form.number.length - 4)
            cvv = form.cvc

            // TODO handle unexpected errors
            const client = await props.createClient(bookingPayload)
            if(client?.isNewClient){
                bookingPayload.clientId = client.id;
            }

            if (paymentGateway === 'workiz') {
                cardToken = await props.getWePayToken(paymentPayload)
                
                if (cardToken.error_code) {
                    let {validations} = form
                    validations.number.error = cardToken.error_description
                    validations.number.status = false
                    setForm(prev => ({...prev, validations}))
                    isFormValid()
                    props.setIsLoading(false)
                    return;
                }
            } else if (paymentGateway === 'workiz-stripe') {
                const {error, setupIntent} = await confirmSetupIntent(client.id);
                
                if (error || !isFormValid()) {

                    switch (error.code) {
                        case "incomplete_number":
                            validations.number.error = "Credit number is not valid"
                            break
                            return
                        case "incomplete_expiry":
                            validations.expiration.error = "Expiration date is not valid"
                            break
                            return
                        case "incomplete_cvc":
                            validations.cvc.error = "CVV number is not valid"
                            break
                            return

                    }
                    setForm(prev => ({...prev,validations}))
                    props.setIsLoading(false)
                    return
                }

                cardToken = setupIntent.payment_method;
            }

            const saveCardPayload = {
                "account_id": props.account.accountId,
                "client_id": client.id,
                "card_token": cardToken,
                "exp_month": expMonth,
                "exp_year": expYear,
                "card_type": cardType,
                "hash": client.hash,
                "last_4_digits": lastFourDigits,
                "card_cvv": cvv
            }
            await props.saveClientCardToken(saveCardPayload)

            await props.saveBooking(bookingPayload);
            
            trackEventToAll('bookingFinishedPaymentStep',{
                numItems: props.numOfServices,
                totalPrice: props.servicesTotalAmount + props.totalTax,
                depositPrice: getDepositAmount()
            });

            props.setIsLoading(false)
            props.onDone()
        }

    }));

    const errorColor = "#FF6F64"

    const initialState =  {
        validations : {
            name: {
                error:'',
                status: false
            },
            number: {
                error:'',
                status: false
            },
            cvc: {
                error:'',
                status: false
            },
            expiration: {
                error:'',
                status: false
            }
        },
        name: '',
        focused: '',
        number: '',
        cvc: '',
        expiration: '',
        cardType: ''
    };

    const [form,setForm] = useState(initialState)

    useEffect(() => {
        formatExpirationDate(form.expiration)
    }, [form.expiration])

    useEffect(() => {
        if (paymentGateway === 'workiz-stripe') {
            const validations = { ...form?.validations };
            validations.number.status = validations.cvc.status = validations.expiration.status = true;
            setForm(prev => ({ ...prev, validations }));
        }
    }, [paymentGateway])

    useEffect(() => {

        if (props.account.paymentSettings?.gateway === 'workiz') {
            //load wePay
            const script = document.createElement("script");
            script.src = "https://static.wepay.com/min/js/tokenization.4.0.2.js";
            script.async = true;
            document.body.appendChild(script);
        }
        if (props.form.creditHolderName) {
            setForm(prev => ({...prev, 'name': props.form.creditHolderName}));
        }
    }, [props.form])


    const validateCardHolder = () => {
        let {validations} = form
        props.updateForm({type:'creditHolderName',data:form.name})
        if(form.name.trim().length < 3)
        {
            validations.name.error = "Name on card is not valid"
            validations.name.status = false
        }else{
            validations.name.error = ""
            validations.name.status = true
        }
        setForm(prev => ({...prev,validations}))
        isFormValid()
    }

    const validateCardNumber = () => {
        let {validations} = form
        let numberValidation = valid.number(form.number);

        if (!numberValidation.isValid || form.number.length < 10) {
            validations.number.error = "Credit number is not valid"
            validations.number.status = false
        }
        else if (numberValidation.card) {
            setForm(prev => ({...prev,cardType: numberValidation.card.type}))
            validations.number.error = ""
            validations.number.status = true
        }

        setForm(prev => ({...prev,validations}))
        isFormValid()
    }

    const validateCvc = () => {
        let {validations} = form
        const maxLength = form.cardType === 'american-express' ? 4 : 3
        let cvvValidation = valid.cvv(form.cvc,maxLength );

        if (!cvvValidation.isPotentiallyValid || form.cvc.length < 3) {
            validations.cvc.error = "CVV number is not valid"
            validations.cvc.status = false
        }
        else{
            validations.cvc.error = ""
            validations.cvc.status = true
        }
        setForm(prev => ({...prev,validations}))
        isFormValid()
    }

    const validateExpiration = () => {
        let {validations} = form

        if(!valid.expirationDate(form.expiration).isPotentiallyValid){
            validations.expiration.error = "Expiration date is not valid"
            validations.expiration.status = false
        }else{
            validations.expiration.error = ""
            validations.expiration.status = true
        }
        setForm(prev => ({...prev,validations}))

        isFormValid()
    }

    const handleInputFocus = ({ target }) => {
        setForm(prev => ({...prev,
            focused: target.name
        }))
        isFormValid()
    }

    const formatExpirationDate = (value) => {
        const clearValue = value.replace(/\D+/g, "");
        if (clearValue.length >= 3) {
            let formatted = `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
            setForm(prev => ({...prev, expiration :formatted}));
        }else{
            setForm(prev => ({...prev, expiration :clearValue}));
        }

    }

    const handleCreditNumberChange = (event) => {
        const target = event.target;
        target.value = event.target.value.replace(/\D/g,'').substring(0,19).replace(/(.{4})/g, '$1 ').replace(/( $)/g, '')

        handleInputChange(event)
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        
        if(target.name == 'name' && value.length > 40) return;

        const name = target.name;
        setForm(prev => ({...prev, [name]: value}));
        isFormValid()
    }

    const isFormValid = () => {
        let valid = true;
        for (const [key] of Object.entries(form.validations)) {
            if (form.validations[key].status === false) {

                valid = false
            }

        }
        const captchaValid = displayCaptcha && captchaToken || !displayCaptcha;
        props.isValid(valid && captchaValid);
        return valid
    }

    const getQuestionMarkTooltipContent = () => {
        return(
            <span className='cvv-tooltip-content'>
                <div className='img-wrap'>
                    <img src={creditCardSVG}/>
                    <div className='green'>CVV</div>
                </div>
                <div className='line'></div>
                <div>The <span className='green'>3 digit</span> number located at the back of your credit card</div>
            </span>
        )
    }

    const renderCvvQuestionMark = () => {
        return(
            <Tooltip
                className='tooltip'
                tipContentClassName='tooltip-content'
                tagName='span'
                content={getQuestionMarkTooltipContent()}
                arrowSize={4}
                background='#3B4C53'
                color='#FFFFFF'
            >
                <img src={questionMark} alt="question mark icon" className='question-mark question-mark-container'/>
            </Tooltip>
        )

    }


    const renderStripe = () => {
        if (!stripePromise) {
            return <div>no stripe promise</div>
        }
        return (
            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({stripe, elements}) => {
                        stripeObj = stripe;
                        elementsObj = elements
                        return (
                            <div className="StripeElement">
                                <div>
                                    <Grid columns={'twelve'} extraClass="fLabel relative">
                                        <CardNumberElement options={{style: style}} id='card-number' className='sajInput'/>
                                        <label className='label' htmlFor='card-number'>Card number</label>
                                        <div className="stripe-error">{form.validations.number.error}</div>
                                    </Grid>
                                    <div className="expiration-and-cvv">
                                        <Grid columns={'four'} extraClass="fLabel relative">
                                            <CardExpiryElement options={{style: style}} id='card-expiry' className='sajInput half-size'/>
                                            <label className='label' htmlFor='card-expiry'>Expires on</label>
                                            <div className="stripe-error">{form.validations.expiration.error}</div>
                                        </Grid>
                                        <Grid columns={'four'} extraClass="fLabel relative">
                                            <CardCvcElement options={{style: style}} id='card-cvc' className='sajInput half-size'/>
                                            <label className='label' htmlFor='card-cvc'>CVV</label>
                                            <div className="stripe-error">{form.validations.cvc.error}</div>
                                        </Grid>
                                        {renderCvvQuestionMark()}
                                    </div>
                                </div>

                            </div>
                        );
                    }}
                </ElementsConsumer>
            </Elements>
        )
    }

    const renderWepay = () => {
        return <div className="wepay-fields-wrapper">
            <TextField
                defaultValue={form.number}
                name="number"
                label="Credit Card Number"
                helperText={form.validations.number.error}
                FormHelperTextProps={{ style: { color: errorColor }}}
                variant="outlined"
                onFocus={handleInputFocus}
                className="input-field credit-card"
                onChange={handleCreditNumberChange}
                onBlur={validateCardNumber}
                onKeyUp={validateCardNumber}
            />

            <div className="expiration-wrapper">
                <TextField
                    value={form.expiration}
                    name="expiration"
                    label="Expiration Date"
                    placeholder="mm/yy"
                    helperText={form.validations.expiration.error}
                    FormHelperTextProps={{ style: { color: errorColor }}}
                    variant="outlined"
                    onFocus={handleInputFocus}
                    className="input-field expiration"
                    onChange={handleInputChange}
                    onBlur={validateExpiration}
                    onKeyUp={validateExpiration}
                />
                <TextField
                    defaultValue={form.cvc}
                    name="cvc"
                    label="CVV"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',maxLength: 4 }}
                    FormHelperTextProps={{ style: { color: errorColor }}}
                    helperText={form.validations.cvc.error}
                    variant="outlined"
                    className="input-field cvv"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    onBlur={() => validateCvc()}
                    onKeyUp={() => validateCvc()}
                />
            </div>
        </div>
    }

    return (
        <div className="payment">
            <StepTitle title="Please enter payment details"/>
            <div className='card-wrapper'>
            <div className={`card ${paymentGateway === 'workiz-stripe' ? 'stripe' : 'wepay'}`}>
                <img className="background-image" alt="bg" src={mobileSize ? BackgroundMobile : Background}/>
                <div className="top">
                    <div className="fields-wrapper">
                        <div className="holder-name-wrapper">
                            <TextField
                                value={form.name}
                                name="name"
                                label="Name on card"
                                helperText={form.validations.name.error}
                                variant="outlined"
                                FormHelperTextProps={{style: {color: errorColor}}}
                                className="input-field"
                                onFocus={handleInputFocus}
                                onChange={handleInputChange}
                                onBlur={() => validateCardHolder()}
                                onKeyUp={() => validateCardHolder()}
                            />
                        </div>

                        {paymentGateway === 'workiz-stripe' && renderStripe()}
                        {paymentGateway === 'workiz' && renderWepay()}
                    </div>
                    {   paymentGateway === 'workiz' ?
                        <div className="card-wrapper">
                            {!mobileSize && <div className="preview">Preview</div>}
                            <Cards
                                cvc={form.cvc}
                                focused={form.focused}
                                expiry={form.expiration}
                                name={form.name}
                                number={form.number}
                            />
                        </div>
                        :
                        <img className="credit-card-img" src={CreditCard} />
                    }

                </div>
                    <TotalDeposit />
            </div>
            </div>
            { displayCaptcha && <ReCAPTCHA
                sitekey={`${config.CAPCHA_SITE_KEY}`}
                onChange={(e) => setCaptchaToken(e)}
            /> }
        </div>
    )
})


const mapStateToProps = (state,ownProps) => {
    return {
        account: state.account,
        services: state.services,
        numOfServices: state.servicesTotals.numOfServices,
        servicesTotalAmount: state.servicesTotals.totalAmount,
        totalTax: state.servicesTotals.totalTax,
        form: state.form,
        isLoading: state.isLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getWePayToken: (payload) => dispatch(getWePayToken(payload)),
        saveBooking: (payload) => dispatch(saveBooking(payload)),
        createClient: (payload) => dispatch(createClient(payload)),
        saveClientCardToken: (payload) => dispatch(saveClientCardToken(payload)),
        getSetupIntent: (payload) => dispatch(getSetupIntent(payload)),
        updateForm: (payload) => dispatch(updateForm(payload)),
        setIsLoading: (toggle) => dispatch(setIsLoading(toggle))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true}
)(Payment);

