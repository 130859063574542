import React from 'react';
import './Select.scss'

const Select = (props) => {
    const onSelect = () => {
        props.onClick(props.item.id)
    }

    return (
        <div className={"container"} onClick={onSelect}>
            <p>
                {props.item.type_name}
            </p>
        </div>
    )

}

export default Select;