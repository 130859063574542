import React from "react";
import styles from "../DateSelect.module.scss";

const DateDay = ({ day, onDone, selected, available }) => {
    const today = day.isSame(new Date(), "day");
    const isSelected = day.isSame(selected, "day") ? styles.selected : "";
    let disabled = available ? "" : styles.disabled;
    let todayClass = today ? styles.today : "";
    const selectDate = date => {
        if (!disabled) onDone(date);
    }

    return (
        <div
            className={`${styles.day} ${isSelected} ${disabled} ${todayClass}`}
            onClick={() => selectDate(day)}
        >
            <div className={styles.dayName}>{day.format('ddd')}</div>
            <div className={styles.dayDate}>{day.format('D')}</div>
            {today && <span className={styles.today}></span>}
        </div>
    )
}

export default DateDay;