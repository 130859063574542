import React, {Component, createRef} from 'react';
import {connect} from "react-redux";
import './App.scss';
//load js helpers
import moment from 'moment';
import * as urlHelper from '@helpers/urlHelper';
import {getSteps,steps} from '@helpers/steps';
import {trackEventToAll} from "@helpers/eventsHelper";
import { amplitudeEvent } from '@api/amplitude';
import {initSentry} from '@helpers/sentryHelper';
import {getResolution} from '@helpers/windowHelper';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { google } from "calendar-link";

//load redux actions
import {setAccount,getExtendedProperties} from './actions/accountActions'
import {updateServices, updateServicesTotalAmount, resetServiceTotal} from './actions/servicesActions'
import {updateSelectedJobType} from './actions/jobTypeAction'
import { updateForm } from "@actions/formActions"

//load containers
import Header from '@containers/Header/Header';
import ServicesList from '@containers/Services/ServicesList';
import ClientDetails from '@containers/ClientDetails/ClientDetails';
import Payment from "@containers/Payment/Payment";
import SuccessPage from '@containers/SuccessPage/SuccessPage';
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal';
import NotFound from '@containers/NotFound/NotFound';
import JobTypes from '@containers/JobTypes/JobTypes';
import ActionBar from './components/ActionBar/ActionBar';
import {CircularProgress} from '@material-ui/core';
import config from './config';

import axios from "@api/axios";
import { initFullStory } from '@api/fullStory'
import {createAvailableSlots} from "@helpers/calendarHelper";
import ServiceDetailsModal from './containers/ServiceDetailsModal/ServiceDetailsModal';
import * as flagNames from './services/featureFlag/featureFlagConstants';
import { initFeatureFlag,isEnabled } from './services/featureFlag/featureFlag';
import StepsBar from './components/StepsBar/StepsBar';
import DateSelect from './containers/DateSelect/DateSelect';
import WithOutsideClick from "@helpers/clickOutsideWrapper";
import Cart from './containers/Cart/Cart';

window.react = {}

const hours = {start: 0};
const defaultHours = {
    0: {...hours},
    1: {...hours},
    2: {...hours},
    3: {...hours},
    4: {...hours},
    5: {...hours},
    6: {...hours},
}
const CartWrapper = WithOutsideClick(Cart);
class App extends Component {

    state = {
        isLoading: false,
        isClientDetailsValid: false,
        isPaymentDetailsValid: false,
        services: [1],
        enabledServices: [],
        slotLength: 1,
        takenSlots: null,
        notFound: false,
        showRequiredFields: false,
        outCalendarDays: null,
        serviceToShow:null,
        selectedJobType: null,
        techsTotalByJobTypes:{},
        jobTypes: [],
        selectedJobServices:[],
        steps:[],
        stepIndex:0,
        isActionLoading: false,
        isEmbedded:true,
        useJobTypes:true
    };

    goToNextStep = () => {
        const currentStep = this.state.steps[this.state.stepIndex];
        const totalPay = this.props.totalAmount + this.props.totalTax;
        const amplitudeParams = {
            accountId: this.props.account.accountId,
            paymentsEnabled: totalPay > 0 && !this.isPaymentsDisabled(),
            serviceTypeEnabled: this.state.useJobTypes > 0 && this.state.jobTypes.length > 0
        }

        if(currentStep.key === steps.CLIENT_DETAILS.key){
            this.clientDetailsRef.current.next();
            return;
        }

        if(currentStep.key === steps.PAYMENT.key){
            this.paymentRef.current.next();
            return;
        }

        if (currentStep.key === steps.ITEMS.key) {
            const steps = getSteps(
                this.state.useJobTypes > 0 && this.state.jobTypes.length,//with job types
                true,//with items
                totalPay && !this.isPaymentsDisabled()//with payments
            )
            this.setState({ steps })
            
            amplitudeEvent("bookingFinishedItemsStep", {
                ...amplitudeParams,
                totalPrice: totalPay,
                itemsEnabled: true,
            })
        }

        if (currentStep.key === steps.DATETIME.key) {
            amplitudeEvent("bookingFinishedScheduleStep", {
                ...amplitudeParams,
                itemsEnabled: (this.state.enabledServices || []).length > 0,
            })
        }

        const nextIndex = this.state.stepIndex + 1;
        
        this.setState({stepIndex: nextIndex});
    }

    goBack = (goToFirstStep = false) => {
        const nextStep = goToFirstStep ? 0 : this.state.stepIndex - 1;
        if(this.state.steps[nextStep])
        {
            this.setState({stepIndex: nextStep})
        }
        const _nextStep = this.state.steps[nextStep]
        if (_nextStep.key === steps.JOB_TYPES.key || goToFirstStep)
        {
            // back to the first step
            this.props.dispatchUpdateForm({type: 'date', data: null});
            this.props.dispatchUpdateForm({type: 'time', data: null});
            this.props.dispatchUpdateForm({type: 'dateNavDay', data: null});
            this.resetServicesAmount()
            this.onSelectedJobType(null,false)
        }
    }

    openServiceDetails = (service) => {
        this.setState({serviceToShow:service})
    }

    closeServiceDetails = () => {
        this.setServiceDetails(null)
    }

    isPaymentStepValid = (isValid) => {
        this.setState({isPaymentDetailsValid: isValid})
    }

    isClientDetailsStepValid = (isValid) => {
        this.setState({isClientDetailsValid: isValid})
    }

    isNextStepDisabled = () => {
        const currentStep = this.state.steps[this.state.stepIndex];
        if(!currentStep) return false;
        switch (currentStep.key) {
            case steps.JOB_TYPES.key:
                return !this.props.selectedJobType;
            case steps.ITEMS.key:
                return this.props.numOfServices === 0;
            case steps.DATETIME.key:
                return !(this.props.form.date && this.props.form.time !== null)
            case steps.CLIENT_DETAILS.key:
                return !this.state.isClientDetailsValid
            case steps.PAYMENT.key:
                return !this.state.isPaymentDetailsValid
            default:
                return true
        }
    }

    redirectToThankYouPage = () => {
        window.top.location = this.props.account.thankYouUrl;
    }

    onClientDetailsDone = (hasPayment) => {
        if(hasPayment){
            this.setState({stepIndex: this.state.stepIndex+1});
        }else{
            if(this.props.account.thankYouUrl.length > 0){
                this.redirectToThankYouPage();
            }else{
                this.setState({stepIndex: this.state.stepIndex+1});
            }
        }
    }

    onPaymentDone = () => {
        if(this.props.account.thankYouUrl){
            this.redirectToThankYouPage();
        }else{
            this.setState({stepIndex: this.state.stepIndex+1});
        }
    }

    onSelectedJobType = async (selectedJobType,goNext = true) => {
        let {accountSettings} = this.state
        if (selectedJobType !== this.state.selectedJobType) {
            const total_techs = this.state.techsTotalByJobTypes[selectedJobType] ? this.state.techsTotalByJobTypes[selectedJobType].length :await this.getActiveUsers()
            this.setState({
                accountSettings: {...accountSettings, total_techs: total_techs},
            })
            this.setState({selectedJobType: selectedJobType}, this.loadSlots);
            this.setServiceByJobType(selectedJobType);
            this.props.dispatchUpdateSelectedJobType(selectedJobType)
            if(goNext) this.goToNextStep()
        }
    }

    resetServicesAmount = async () => {
        const services = await this.props.getExtendedProperties(this.props.account.ac)
        this.props.dispatchUpdateServices(services)
        this.props.dispatchResetServiceTotal()
    }

    renderStep = () => {
        if(this.state.isLoading) return <div className="main-loader"><CircularProgress/></div>
        const currentStep = this.state.steps[this.state.stepIndex];
        if(!currentStep) return null;
        switch (currentStep.key) {
            case steps.JOB_TYPES.key:
                return(
                    <JobTypes
                        jobTypes = {this.state.jobTypes}
                        onSelectedJobType = {(jobType)=>this.onSelectedJobType(jobType)}
                    />
                )
            case steps.ITEMS.key:
                return(
                    <ServicesList
                        openServiceDetails = {(service)=>this.openServiceDetails(service)}
                        hasJobTypes={this.state.jobTypes.length > 0}
                    />
                )
            case steps.DATETIME.key:
                return(
                    <DateSelect
                        takenSlots={this.state.takenSlots}
                        accountSettings={this.state.accountSettings || {}}
                        workingTimes = {this.state.accountSettings.working_times_obj}
                        scheduleOffset={this.state.accountSettings.schedule_offset || 0}
                        slotLength={this.state.accountSettings.slot_length || 1.00}
                        availableBased ={this.state.accountSettings.available_based || 0}
                        availableJobsPerSlot ={this.state.accountSettings.availble_techs}
                        totalTechs = {this.state.accountSettings.total_techs}
                        outCalendarDays={this.state.outCalendarDays}
                        goToNextStep={this.goToNextStep}
                        loadSlots={(startDateRange, endDateRange) => {
                            this.loadSlots(startDateRange, endDateRange);
                        }}
                    />
                )
            case steps.CLIENT_DETAILS.key:
                return(
                    <ClientDetails
                        ref={this.clientDetailsRef}
                        isValid={(valid) => this.isClientDetailsStepValid(valid)}
                        onDone={(hasPayment) => this.onClientDetailsDone(hasPayment)}
                        showRequired={this.state.showRequiredFields}
                        jobTypes = {this.state.jobTypes}
                        selectedJobType={this.state.selectedJobType}
                    />
                )
            case steps.PAYMENT.key:
                return(
                    <Payment
                        ref={this.paymentRef}
                        isValid={(valid) => this.isPaymentStepValid(valid)}
                        onDone={() => this.onPaymentDone()}
                        jobTypes = {this.state.jobTypes}
                        selectedJobType={this.state.selectedJobType}
                    />
                )
            case steps.SUMMARY.key:
                return (
                    <SuccessPage
                        accountSettings = {this.state.accountSettings}
                        jobTypes = {this.state.jobTypes}
                        selectedJobType={this.state.selectedJobType}
                    />
                )
            default:
                return null
        }
    
    }

    getWorkingHours = (working_times, slot_length) => {
        slot_length = slot_length ? slot_length : 1.00;
        let working_times_obj = JSON.parse(working_times || "{}");
        working_times_obj = working_times_obj || {};
        if (!Object.keys(working_times_obj).length) {
            working_times_obj = {...defaultHours};
            return working_times_obj;
        }
        for (let i in working_times_obj) {
            working_times_obj[i] = working_times_obj[i] || {};
            working_times_obj[i].lastSlot = Math.floor(((working_times_obj[i].end || 0) - (working_times_obj[i].start || 0)) / slot_length);
        }
        return working_times_obj;
    }
    
    loadSlots = async (startDateRange = null, endDateRange = null) => {
        const DATE_RANGE = 25;
        let startDate
        let endDate
        if (startDateRange !== null && endDateRange !== null) {
            startDate = moment(startDateRange).clone().format("YYYY-MM-DD");
            endDate = moment(endDateRange).clone().format("YYYY-MM-DD");
        } else {
            startDate = moment().format("YYYY-MM-DD");
            endDate = moment().add(DATE_RANGE, 'days').format("YYYY-MM-DD");
        }

        //TODO cheack then conmes new one
        let {ac, uc, accountSettings, jobTypes, techsTotalByJobTypes, selectedJobType} = this.state
        const use_job_type = accountSettings.use_job_types == 1 && jobTypes.length > 0
        let techsArray = []
        if(use_job_type && selectedJobType){
            techsArray = techsTotalByJobTypes[selectedJobType] ?? []
        }

        this.setState({
            takenSlots:{},
        })

        axios.post(`${config.BOOKING_APP_API_URL}/getSlots`, {
            ac, uc,
            action: 'getSlots',
            slotLength: accountSettings.slot_length,
            startAt: accountSettings.working_start_time,
            available_based: accountSettings.available_based,
            startDate,
            endDate,
            use_job_type,
            techsArray
        }).then(res => {
            if (res.data.flag && Array.isArray(res.data.data)) {
                const takenSlots = {};
                const data = res.data.data;
                let outCalendarDays = [];
                data.forEach(item => {
                    takenSlots[item.job_day] = takenSlots[item.job_day] || {};
                    takenSlots[item.job_day][item.slot_key] = {
                        tech_in_day_off_and_job: item.tech_assigned_to_jobs_and_day_offs,
                        jobs_in_slot: item.jobs_in_slot
                    };
                });
                if(accountSettings.available_based !== 0 ){
                    if(!this.state.accountSettings.working_times_obj){
                        this.setState({
                            accountSettings: {...accountSettings}
                        })
                    }

                    outCalendarDays = this.getBusyDays(this.state.accountSettings, takenSlots);   
                } 

                this.setState({
                    takenSlots: takenSlots,
                    outCalendarDays,
                    accountSettings: {...accountSettings}
                });
            }
        });
    }

    constructor(props) {
        super(props);
        initSentry()
        this.clientDetailsRef = createRef();
        this.paymentRef = createRef();
    }

    getUserFields = (userFields, region) => {
        if(Object.keys(userFields).length > 0){
            if(userFields.state && (region == 'NON' || region == null)){
                delete userFields.state;
            }
            return userFields
        }else{
            return {
                "first_name": {"label":"Your First Name","type":"text","required":true,"position":0},
                "last_name": {"label":"Your Last Name","type":"text","required":true,"position":1},
                "email_address":{"label":"Email Address","type":"email","required":true,"position":2}            }
        }

    }

    isPaymentsDisabled = () => {
        return this.props.account.paymentSettings?.deposit === -1 || this.state.enabledServices?.length === 0;
    }

    getEnabledServices = async (ac) => {
        const services = await this.props.getExtendedProperties(ac)
        return (services && services.length > 0 && services.filter((service) => {
            return service.status === "1"
        })) || [];
    }

    async componentDidMount() {
        this.setState({isLoading: true})

        const query = window.location.search;
        const params = urlHelper.decodeQuery(query);
        const ac = params.ac;
        const uc = params.uc;
        const fromGLS = params.fromgls === 'true';
        const rwgToken = params.rwg_token ?? false;
        let storedRwgToken = {[ac]:{rwgToken,time:Date.now()}};
        if(rwgToken){
            try {
                storedRwgToken = JSON.parse(localStorage.getItem('rwg_token_v2')) || {};
                if (!storedRwgToken[ac]) {
                    storedRwgToken[ac] = {};
                }        
                storedRwgToken[ac].rwgToken = rwgToken;
                storedRwgToken[ac].time = Date.now();
            } catch (e) {
                console.error(e);
            }
            localStorage.setItem('rwg_token_v2', JSON.stringify(storedRwgToken));
        }
        const urlAdGroup = params.ad_group;
        await axios.post(`${config.BOOKING_APP_API_URL}/getSettings`, {ac:ac, uc:uc, action: 'getSettings'}).then( async res => {
            const settings = res.data.data;
            const enabledServices = await this.getEnabledServices(ac);

            if(fromGLS && enabledServices.length === 0)
                settings.use_job_types = true;
            this.props.dispatchSetAccount({
                accountId: settings.account_id,
                ac: ac,
                accountLogo: settings.account_logo,
                uselogo: settings.uselogo,
                adGroup: settings.ad_group,
                adGroupName: urlAdGroup,
                color: settings.color,
                title: settings.title,
                description: settings.description,
                schedule_offset: settings.schedule_offset,
                thankYouUrl: settings.thank_you_url,
                slotLength: settings.slot_length,
                telephone: settings.sms_notification,
                email: settings.email_notification,
                userFields: this.getUserFields(settings.user_fields ? JSON.parse(settings.user_fields) : [], settings.region),
                region: settings.region,
                useJobTypes: settings.use_job_types,
                jobTypes: settings.selected_job_types,
                createAutoJobOrLead: settings.create_auto_job_or_lead,
                account_name: settings.account_name,
                geo: settings.geo,
            })

            initFullStory(settings.account_id)
            initFeatureFlag(this.props.account.accountId);
            this.setState({ enabledServices: enabledServices, useJobTypes: settings.use_job_types })
            if (res.data.flag && res.data.data) {
                let accountSettings = settings || {};
                if (uc && accountSettings.available_based === 1) {
                    accountSettings.availble_techs = 1;
                }
                if(settings.use_job_types){
                    let techsTotal = await this.getTotalTechsByJobType(ac)
                    this.setState({techsTotalByJobTypes:techsTotal})            
                }else if(this.state.jobTypes.length == 0){
                    accountSettings.total_techs =await this.getActiveUsers()
                }

                this.setState({
                    ac, 
                    uc, 
                    accountSettings, 
                    slotLength: accountSettings.slot_length,
                    isLoading:false
                });
                const jobTypes = await this.getJobTypes()
                this.setState({jobTypes})
                const isEmbedded = window.location != window.parent.location;
                this.setState({
                    accountSettings:{...accountSettings,
                    working_times_obj:this.getWorkingHours(settings.working_times, settings.slot_length)
                    },
                    isEmbedded
                }, this.loadSlots)
                
                trackEventToAll('bookingPageLoaded',{
                    urlReferral:document.referrer,
                    isEmbedded:isEmbedded,
                    paymentsEnabled: !this.isPaymentsDisabled(),
                    itemsEnabled: enabledServices?.length > 0,
                    screenResolution: getResolution()
                });
            }


            // get an array of relavant steps from steps.js
            // We navigate with stepIndex within the array
            // can condition steps with the steps key 
            // Example currentStep.key === steps.CLIENT_DETAILS.key
            const steps = getSteps(
                this.state.useJobTypes>0 && this.state.jobTypes.length,//with job types
                enabledServices?.length > 0,//with items
                !this.isPaymentsDisabled()//with payments
            )
            this.setState({steps})
        }).catch((e)=>{  
            console.error(e);
            this.setState({isLoading: false, notFound: true})
        });


    }

    //calculate daysOutCalendar
    getBusyDays = (accountSettings, takenSlots) => {
        if(!accountSettings.working_times_obj) return;
        let  busyDaysSlots = busyDaysSlots || {};  
        let newOutDays = this.state.outCalendarDays ? [...this.state.outCalendarDays]:[]
        // Calculate each day availability hours
        for (let date in takenSlots) { 
            let parsedDate = moment(date, 'DD-MM-YY').format('LLLL');
            busyDaysSlots = createAvailableSlots(parsedDate, takenSlots, accountSettings.working_times_obj, accountSettings.slot_length, accountSettings.schedule_offset, accountSettings.available_based, accountSettings.availble_techs, accountSettings.total_techs);                                
            if(busyDaysSlots.length == 0 && !newOutDays.includes(date)){
                newOutDays.push(date);                
            }
        }
        const firstPossibleAvailableDate = moment(new Date()).add(accountSettings.schedule_offset, 'hours')
        let dateForCheck = new Date();
        if(moment(dateForCheck).format('DD-MM-YY') != firstPossibleAvailableDate.format('DD-MM-YY')){
            while(firstPossibleAvailableDate.isAfter(dateForCheck)){
                newOutDays.push(moment(dateForCheck).format('DD-MM-YY'));
                dateForCheck.setDate(dateForCheck.getDate()+1)
            }
        }
        return newOutDays;
    }

    getActiveUsers = async () => {
        const total_techs = await axios.post(`${config.WORKIZ_BOOKING_API}/getActiveUsers/`,{accountId: this.props.account.accountId})
        return  total_techs.data.total_techs
    }

    getJobTypes = async () => {
        const url = new URL(window.location.href);
        const urlParams = new URLSearchParams(url.search);
        const fromGLS = urlParams.has('fromgls') && urlParams.get('fromgls') === 'true';
        const typesArray = [];
        const featureFlagOn = await isEnabled(flagNames.JOB_TYPES_BOOKING, false);
        if(featureFlagOn){
            const response = await axios.post(`${config.WORKIZ_BOOKING_API}/getJobTypes/`,{accountId: this.props.account.accountId})
            const allJobTypes = response.data.jobTypes
            const bookingJobTypes = fromGLS ? this.props.googleJobTypes : this.props.account.jobTypes ? JSON.parse(this.props.account.jobTypes) : [];
            if(allJobTypes.length > 0){
                bookingJobTypes.forEach(type => {
                    let typeData = allJobTypes.find(t => t.id == type)
                    if(typeData) typesArray.push(typeData)
                })
            }
            if (this.state.accountSettings.use_job_types) {
                amplitudeEvent("bookingPageJobTypesLoaded", {
                    numOfServices: typesArray.length
                })
            }
        }
        return typesArray;
    }


    setServiceByJobType = async (type) => {
        const services = this.state.enabledServices || [];
        const selectedJobServices = services.filter(service => service.job_types == type || service.job_types == "" || service.job_types === undefined || service.job_types === null)
        const serviceItemsEnabled = type === null ? true : selectedJobServices.length ? true : false;
        const steps = getSteps(
            this.state.useJobTypes > 0 && this.state.jobTypes.length,//with job types
            services.length && serviceItemsEnabled,//with items
            !this.isPaymentsDisabled() && serviceItemsEnabled//with payments
        )
        amplitudeEvent("bookingFinishedServiceStep", {
            paymentsEnabled: !this.isPaymentsDisabled() && serviceItemsEnabled,
            serviceTypeEnabled: this.state.useJobTypes > 0 && this.state.jobTypes.length > 0,
            itemsEnabled: services.length && serviceItemsEnabled,
            accountId: this.props.account.accountId
        })
        this.setState({ steps })
        this.props.dispatchUpdateServices(selectedJobServices)
        this.setState({selectedJobServices:selectedJobServices})
    }

    getTotalTechsByJobType = async  (ac) => {
        const total_techs_by_type = {}
        axios.post(`${config.BOOKING_APP_API_URL}/getTechsByJobType`, {
            ac: ac,
            action: 'getTechsByJobType',
        }).then(res => {
            this.setState({isLoading: false})
            if (res.data.flag) {
                const job_types = res.data.data;
                job_types.forEach((element) => {
                    total_techs_by_type[element.job_type_id] = element.techs_id.split(','); 
                })
            }
        })
        return total_techs_by_type;
    }

    addToCalendar = () => {
        amplitudeEvent('bookingAddedToCalendar');
        let event = {
            title: "",
            description: "",
            start: null,
            duration: [2, "hour"],
        };
        const {services,account,form} = this.props;
        let _services = ""

        services.forEach((service) => {
            if(service.quantity > 0){
                _services += `${service.item_name} - ${service.quantity} <br>`
            }
        })
        try {
            event.title = account.title
            const phone =  `Business Phone: ${account.telephone}`
            event.description = `${_services} <br> Business Email: ${account.email} <br> ${account.telephone ? phone : ''}`
            event.start = moment(form.date).hour(0).minute(0).add(form.time, 'hours')
            event.location = form.fullAddress.address
            if(form.fullAddress.city){
                event.location += ` ${form.fullAddress.city}`
            }
            if(form.fullAddress.state){
                event.location += ` ${form.fullAddress.state}`
            }
            if(form.fullAddress.zipcode){
                event.location += ` ${form.fullAddress.zipcode}`
            }

            window.open(google(event), '_blank').focus();
        } catch (error) {
            console.log(error)
        }
        
    }

    render() {
        if(this.state.notFound) return <NotFound/>
        return (
           <>
               {this.props.confirmationModal.show && <ConfirmationModal/>}
               <div className={`booking`}>
                    <Header
                        goBack={this.goBack}
                        currentStep={this.state.steps[this.state.stepIndex]}
                        enabledServices={this.state.enabledServices.length>0}
                    />
                   <div className="main">
                        <StepsBar 
                            stepIndex={this.state.stepIndex}
                            steps={this.state.steps}
                        />
                        <div className={`content`}>
                            <div className={`steps`}>
                            <ServiceDetailsModal
                            isOpen={this.state.serviceToShow !== null}
                            closeAction={() => { this.setState({ serviceToShow: null }) }}
                            serviceInfo={this.state.serviceToShow}
                            accountTaxRate={this.props.account.paymentSettings?.tax}
                        />
                            {this.renderStep()}
                            </div>
                        </div>
                    </div>
                    <ActionBar
                        stepIndex={this.state.stepIndex}
                        currentStep={this.state.steps[this.state.stepIndex]}
                        next={this.goToNextStep}
                        back={this.goBack}
                        disableNext={this.isNextStepDisabled()}
                        addToCalendar={this.addToCalendar}
                    />
               </div>
           </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        account: state.account,
        services: state.services,
        form: state.form,
        totalAmount: state.servicesTotals.totalAmount,
        totalTax: state.servicesTotals.totalTax,
        numOfServices: state.servicesTotals.numOfServices,
        isLoading: state.isLoading,
        confirmationModal: state.confirmationModal,
        useJobTypes: state.useJobTypes,
        selectedJobType: state.selectedJobType,
        googleJobTypes: state.googleJobTypes
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getExtendedProperties: (payload) => dispatch(getExtendedProperties(payload)),
        dispatchSetAccount: (payload) => dispatch(setAccount(payload)),
        dispatchUpdateServices: (payload) => dispatch(updateServices(payload)),
        dispatchUpdateSelectedJobType: (payload) => dispatch(updateSelectedJobType(payload)),   
        dispatchResetServiceTotal: (payload) => dispatch(resetServiceTotal(payload)),
        dispatchUpdateForm:(payload) => dispatch(updateForm(payload)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);