import { mobileMode } from "@helpers/mobileHelper";

export const steps = {
    "JOB_TYPES": {
        label: 'Service',
        withCTA: false,
        key: 'JOB_TYPES'
    },
    "ITEMS": {
        label: 'Items',
        withCTA: true,
        key: 'ITEMS'
    },
    "DATETIME": {
        label: 'Schedule',
        withCTA: false,
        key: 'DATETIME'
    },
    "CLIENT_DETAILS": {
        label: 'Details',
        withCTA: true,
        key: 'CLIENT_DETAILS'
    },
    "PAYMENT": {
        label: 'Payment',
        withCTA: true,
        key: 'PAYMENT'
    },
    "SUMMARY": {
        label: 'Summary',
        withCTA: false,
        key: 'SUMMARY'
    }
}

export const getSteps = (
    withJobTypes,
    withItems,
    withPayments
) => {
    let _steps = []
    Object.keys(steps).map(key => {
        if (!withPayments && key === steps.PAYMENT.key) return null;
        if (!withJobTypes && key === steps.JOB_TYPES.key) return null;
        if (!withItems && key === steps.ITEMS.key) return null;
        if (mobileMode() && key === steps.SUMMARY.key) {
            let summaryStep = { ...steps[key] };
            summaryStep.label = "Finish";
            _steps.push(summaryStep)
            return null;
        }
        _steps.push(steps[key])
        return null;
    })
    return _steps;
}