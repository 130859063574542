import React from 'react';
import {connect} from "react-redux";
import './TotalDeposit.scss'

const TotalDeposit = props => {


    const getTotalAmount = () => {
        const tax = props.account.paymentSettings?.tax ? props.totalTax : 0;
        const serviceFeeSettings = props.account.paymentSettings.serviceFeeSettings ?? null;
        const serviceFee = serviceFeeSettings && serviceFeeSettings.value ? props.servicesTotalAmount * (serviceFeeSettings.rate / 100) : 0;
        const serviceAmount = props.servicesTotalAmount;

        return serviceAmount + tax + serviceFee;
    }

    const getDepositAmount = () => {
        return getTotalAmount() * (props.account.paymentSettings.deposit * 0.01)
    }
    return (
        <div className="total-deposit">
            {
                props.account.paymentSettings.deposit > 0 ?
                    <>
                        <span className="total-deposit-title">DEPOSIT:</span>
                        <span className="amount"> ${getDepositAmount().toFixed(2)} </span>
                        <span className="from-total">/ {getTotalAmount().toFixed(2)}</span>
                    </>
                    :
                    <>
                        <span className="total-deposit-title">TOTAL:</span>
                        <span className="amount"> ${getTotalAmount().toFixed(2)}</span>
                    </>
            }
        </div>
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        account: state.account,
        servicesTotalAmount: state.servicesTotals.totalAmount,
        totalTax: state.servicesTotals.totalTax
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TotalDeposit);

