import React from "react";
import Button from "../Button/Button";
import { steps } from "@helpers/steps"
import { useSelector } from "react-redux";
import workizLogo from "@assets/images/workizLogo.svg";
import calendarIcon from "@assets/images/calendarIcon.svg";
import styles from "./ActionBar.module.scss";

/**
 * The Footer Component with cta to control the steps of the wizard
 */
const ActionBar = ({ currentStep, stepIndex, back, next, disableNext, addToCalendar }) => {
    let hasBack = stepIndex > 0;
    if (currentStep && currentStep.key === steps.SUMMARY.key) hasBack = false;
    const showNext = currentStep && currentStep.withCTA;
    const disabled = disableNext ? styles.disabled : "";
    const isLoading = useSelector(state => state.isLoading);
    const disabledBack = isLoading ? styles.disabledBack : "";
    const nextStep = () => {
        if (!disabled) next();
    }

    const addToCalendarButton = () => {
        if (currentStep?.key !== steps.SUMMARY.key) return null;
        return <Button
            style={{display: "unset"}}
            clickHandler={addToCalendar}
            className={`${styles.button} ${styles.secondary} ${styles.right}`}
            text="Add to calendar"
            // iconLeft={"fa fa-calendar"}
            imgIconLeft={calendarIcon}
        />
    }

    return (
        <div className={styles.container}>
            <div className={styles.buttons}>
                <div className={styles.wrapperLeftBtn}>
                {hasBack && <Button
                    clickHandler={ () => disabledBack ? '' : back() }
                    className={`${styles.button} ${styles.secondary} ${styles.back} ${ disabledBack }`}
                    text="Back"
                    iconLeft={"bi bi-chevron-left"}
                />}
                </div>
                <div className={styles.poweredBy}>
                    <a href="https://workiz.com?utm_source=bookingv2" target="_blank" rel="noreferrer" >
                    <div className={styles.poweredByLink}>Powered by</div>
                    <img src={workizLogo} alt="workiz"/>
                    </a>
                </div>
                <div className={styles.wrapperRightBtn}>
                {showNext && <Button
                    clickHandler={nextStep}
                    className={`${styles.button} ${currentStep.key === steps.PAYMENT.key ? styles.paymentBtn : ""} ${disabled}`}
                    text={currentStep.key === steps.PAYMENT.key ? "Submit payment" : "Continue"}
                    iconRight={"bi bi-chevron-right"}
                    isLoading={isLoading}
                />}
                {addToCalendarButton()}
                </div>
            </div>
        </div>
    )
}

export default ActionBar;