import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateServiceQuantity, updateServicesTotalAmount } from '@actions/servicesActions';
import { formatCurrency } from "../../helpers/numbers"
import styles from "./ItemsPane.module.scss"
import minusIcon from '@assets/images/minusIcon.svg';
import plusIcon from '@assets/images/plusIcon.svg';
import {showConfirmationModal} from "@actions/confirmationModalActions";

const LineItem = ({ item, goBackToFirstStep, closeItemsPane }) => {

    const dispatch = useDispatch();

    const account = useSelector(state => state.account);
    const numOfServices = useSelector(state => state.servicesTotals.numOfServices);

    const updateItemQuantity = (action) => {
        if (numOfServices === 1 &&
            item.quantity === 1 &&
            action === 'remove') {
            const payload = {
                show: true,
                content: {
                    title: "Remove last service item from your cart?",
                    subtitle: "All data will be reset and you will be redirected back to the main page",
                    approveText: "Yes, remove",
                    cancelText: "Cancel"
                },
                approveFunction: () => {
                    dispatch(updateServiceQuantity({ serviceId: item.p_id, action }));
                    dispatch(updateServicesTotalAmount({
                        price: item.booking_price,
                        taxable: item.taxable,
                        taxRate: account.paymentSettings?.tax,
                        action
                    }));
                    goBackToFirstStep(true);
                    dispatch(showConfirmationModal({ show: false }))
                    closeItemsPane();
                }
            }

            dispatch(showConfirmationModal(payload))
        } else {
            dispatch(updateServiceQuantity({ serviceId: item.p_id, action }));
            dispatch(updateServicesTotalAmount({
                price: item.booking_price,
                taxable: item.taxable,
                taxRate: account.paymentSettings?.tax,
                action
            }));
        }
    }

    return (
        <div className={styles.item}>
            <div className={styles.name}>
                {item.item_name}
            </div>
            <div className={styles.qty}>
                <div className={styles.remove} onClick={() => updateItemQuantity("remove")}><img src={minusIcon} alt='icon'/></div>
                <div className={styles.qtyNumber}>{item.quantity}</div>
                <div className={styles.add} onClick={() => updateItemQuantity("add")}><img src={plusIcon} alt='icon'/></div>
            </div>
            <div className={styles.price}>
                {formatCurrency(item.booking_price)}
            </div>
        </div>
    )
}

export default LineItem;