import moment from 'moment';

export function createAvailableSlots(choosenDay, takenSlots, workingTimes, slotLength, scheduleOffset, availableBased, avaibleJobsPerSlot, totalTechs){
    if (availableBased==2 && avaibleJobsPerSlot==0){
        return [];  // if availableBased is based on 'Choose Number of Jobs per Slot' and if u have 0 avaibleJobsPerSlot => that means u dont want any jobs 2 be booked
    }
    const date = moment(choosenDay).format('DD-MM-YY');
    let availableSlots = [];
    availableSlots = createSlotsBySettings(date, workingTimes, slotLength);     
    if(availableBased != 0 && takenSlots[date]){ 
        availableSlots = availableSlots.filter((slot, index)=>{
            return isAvailable(takenSlots[date][index],totalTechs, avaibleJobsPerSlot, availableBased);
        })   
    }
    const firstPossibleAvailableDate = moment(new Date()).add(scheduleOffset, 'hours')
    //firs avaibele date
    if(date === moment(firstPossibleAvailableDate).format('DD-MM-YY')){
        const availableFromHour = firstPossibleAvailableDate.hours(); 
        availableSlots = availableSlots.filter(item => item > availableFromHour);        
    } else if(firstPossibleAvailableDate.isAfter(choosenDay)){
        availableSlots = [];
    }
    return availableSlots
}

function isAvailable (currentHour, totalTechs, avaibleJobsPerSlot, availableBased){
    if(currentHour){
        if(availableBased == 2) return avaibleJobsPerSlot > currentHour['jobs_in_slot'];
        else{
            if(!totalTechs) return true;
            // console.log(totalTechs > currentHour["tech_in_day_off_and_job"], 2,currentHour,choosenDay)
            return  totalTechs > currentHour["tech_in_day_off_and_job"];
        }
    }else if(currentHour === undefined){
        return true;
    }
}

// type 1
// techA - type 1 free
// techB - type 2 not free


function createSlotsBySettings (choosenDay, workingTimeSettings, slotLength){
    const timeSlots = [];
    let slots = [];                
    const dayOfWeek = moment(choosenDay, "DD-MM-YY").isoWeekday() - 1; 
    if (workingTimeSettings[dayOfWeek]) {
        const lastSlot = workingTimeSettings[dayOfWeek].lastSlot ? workingTimeSettings[dayOfWeek].lastSlot : Math.floor(24/slotLength);
        const startTime = workingTimeSettings[dayOfWeek].start || 0;  
        for (let i = 0; i < lastSlot; i++) {
            timeSlots.push((i * slotLength) + startTime);
        }
        slots = timeSlots;
    }
return slots;
}