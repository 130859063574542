import React from "react";
import styles from "./StepsBar.module.scss"
import Step from "./Step";

const StepsBar = ({ stepIndex, steps }) => {
    const currentStep = steps[stepIndex];
    if (!currentStep) return null;
    return (
        <div className={styles.container}>
            {
                steps.map((_step, index) => {
                    const isActive = currentStep.key == _step.key;
                    const isDone = index < stepIndex
                    return <Step
                        key={index}
                        isActive={isActive}
                        label={_step.label}
                        isDone={isDone}
                    />
                })
            }
        </div>
    )
}

export default StepsBar;