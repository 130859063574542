import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export function initSentry() {
    if (process.env.REACT_APP_STAGE !== 'prod') return;

    Sentry.init({
        dsn: "https://8b81728979154eda829692e468a15dc3@o120871.ingest.sentry.io/6269093",
    });
}
