import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import './SuccessPage.scss'
import * as dayjs from 'dayjs'
import {mobileMode} from "@helpers/mobileHelper"
import {amplitudeEvent} from "@helpers/eventsHelper";
import { google } from "calendar-link";
import GoogleMaps from "@components/GoogleMaps/GoogleMaps";
import invoice from '@assets/images/invoice.png'
import calendar from '@assets/images/calendar.svg'
import vSign from '@assets/images/successPageV.svg'
import emailIcon from '@assets/images/email.svg';
import phoneIcon from '@assets/images/OutlinedPhone.svg';
import { formatCurrency, formatPercentage } from "../../helpers/numbers";
import { isMobileDevice } from "@helpers/mobileHelper";

const SuccessPage = (props) => {
    const [accountSettings, setAccountSettings] = useState({})
    const [noServicesMode, setNoServicesMode] = useState(false)
    const [contactToShow,setContactToShow] = useState({email:'', number:''});
    const [showContacts, setShowContacts] = useState(false);

    const serviceFeeAmount = props.serviceFee && props.serviceFee.value ? props.servicesTotalAmount * (props.serviceFee.rate / 100) : 0;
    const serviceFeeSettings = props.serviceFee;

    const event = {
        title: "",
        description: "",
        start: null,
        duration: [2, "hour"],
    };

    const mapContainerStyle = {
        position: 'relative',
        width: '100%',
        height: mobileMode() ? '155px' : '120px'
    }

    let eventDate = dayjs(new Date(props.form.date))
    let eventTime = dayjs().hour(0).minute(0).add(props.form.time, 'hours')

    const addToCalendar = () => {
        amplitudeEvent('bookingAddedToCalendar');

        let services = ""

        props.services.forEach((service) => {
            if(service.quantity > 0){
                services += `${service.item_name} - ${service.quantity} <br>`
            }
        })

        event.title = props.account.title
        const phone =  `Business Phone: ${props.account.telephone}`
        event.description = `${services} <br> Business Email: ${props.account.email} <br> ${props.account.telephone ? phone : ''}`
        event.start = dayjs(new Date(props.form.date)).hour(0).minute(0).add(props.form.time, 'hours')
        event.location = props.form.fullAddress.address
        if(props.form.fullAddress.city){
            event.location += ` ${props.form.fullAddress.city}`
        }
        if(props.form.fullAddress.state){
            event.location += ` ${props.form.fullAddress.state}`
        }
        if(props.form.fullAddress.zipcode){
            event.location += ` ${props.form.fullAddress.zipcode}`
        }

        window.open(google(event), '_blank').focus();
    }

    const calcServiceTotalTax = (service) => {
        const tax = ((props.account.paymentSettings?.tax / 100) * (service.booking_price * service.quantity)).toFixed(1)
        return service.taxable == "1" ? `$${tax}` : '-'
    }

    const renderServices = () => {
        return props.services.map(service => {
            if(service.quantity > 0){
                return <div key={service.p_id} className="service-row">
                    <div className="data jobType left">{props.selectedJobType ? props.jobTypes.find(jobType => jobType.id == props.selectedJobType)?.type_name : "No Service"}</div>
                            <div className="data name left">{service.item_name}</div>
                            <div className="data qty right">{service.quantity}</div>
                            <div className={`data total right ${!props.account.paymentSettings?.tax ? 'no-tax' : ''} `}>{formatCurrency(service.booking_price * service.quantity)}</div>
                        </div>
            }else{
                return false
            }
        })
    }

    useEffect(() => {
        setNoServicesMode(props.services.length === 0)
    }, [props.services])

    useEffect(() => {
        setAccountSettings(props.accountSettings)
        if(props.accountSettings?.use_business_contact === 1 ){
            setContactToShow({email: props.accountSettings.business_email, phone: props.accountSettings.business_phone})
            setShowContacts(true);
        } else {
            setShowContacts(false);
            setContactToShow({email: null, phone: null})
        }  
    }, []);

    const pendingNotice = accountSettings?.pending_approval_show === 1? 
            <div className="pending-approval-msg">
                Your request is pending business approval
            </div>
            : null;
 
    const renderContact = () => {
        if (!showContacts) return null;
        return (
            <div className="contact-wrapper">
                <div className='left-side'>CONTACT US:</div>
                <div className='right-side'>
                    <a target="_blank" rel="noreferrer" href={`mailto:${contactToShow.email}`} className = "email">
                        <img src={emailIcon} alt="icon" />
                        {contactToShow.email}
                    </a>
                    {contactToShow.phone &&
                        <div className='phone-wrapper'>
                            { !isMobileDevice() && <div className='dot'>&bull;</div> }
                            <a href={`tel:${contactToShow.phone}`} className = "phone">
                                <img src={phoneIcon} alt="icon" />
                                {contactToShow.phone}
                            </a>
                        </div>
                    }

                </div>
            </div>
        )
    }

    return (
        <div className="success-page">
            <div className="summary">
                <div className={`titles`}>
                    {!noServicesMode ? <div className="title">Thank you for choosing us!</div> : <div className="title">Booking summary</div>}
                    {!mobileMode() && !noServicesMode && pendingNotice}
                </div>
                <div className="summary-card">
                    <div className={`summary-left ${noServicesMode ? "no-padding" : ""} ${ noServicesMode && !pendingNotice ? 'hide' : '' }`}>
                        {mobileMode() && !noServicesMode && pendingNotice}
                        {
                            !noServicesMode && <>
                                <div className="summary-titles">
                                    <div className='left-section left'>
                                        <div className={`title services`}>SERVICE</div>
                                        <div className="title item">ITEM</div>
                                    </div>
                                    <div className='right-section right'>
                                        <div className="title qty">QTY</div>
                                        <div className="title total">TOTAL</div>
                                    </div>
                                </div>
                                <div className="services-wrapper">
                                    <div className={`services ${props.totalTax ? "with-tax" : ""}`}>{renderServices()}</div>
                                    {props.totalTax ? <>
                                        <div className={`sub-total-row ${!props.account.paymentSettings?.tax ? 'no-tax' : ''} `}>
                                            <div className="sub-total">SUBTOTAL</div>
                                            <div className="total-amount">{formatCurrency(props.servicesTotalAmount)}</div>
                                        </div>
                                        <div className={`sub-total-row ${!props.account.paymentSettings?.tax ? 'no-tax' : ''} `}>
                                            <div className="sub-total">{`TAX (${formatPercentage(props.account.paymentSettings?.tax)})`}</div>
                                            <div className="total-amount">{(formatCurrency(props.totalTax))}</div>
                                        </div>

                                    </> : null
                                    }
                                    {serviceFeeSettings?.value ?
                                        <>
                                            <hr />
                                            <div className={`sub-total-row no-tax`}>
                                                <div className="sub-total">{`SERVICE FEE (${formatPercentage(props.serviceFee.rate)})`}</div>
                                                <div className="total-amount">{formatCurrency(serviceFeeAmount)}</div>
                                            </div>
                                        </> : null
                                    }
                                </div>
                                <div className="summary-total">
                                    <div className="items-in-total">TOTAL</div>
                                    <div className="total-amount">{formatCurrency(props.servicesTotalAmount + props.totalTax + serviceFeeAmount)}</div>
                                </div>
                            </>
                        }
                        {
                            noServicesMode && <div className="no-services-mode">
                                {
                                    !mobileMode() && <>
                                        <img alt="success" src={vSign}/>
                                        <div className="thank-you">Thank you for choosing us!</div>
                                    </>
                                }
                                {accountSettings?.pending_approval_show === 1 && <div className="pending-approval">
                                    { props.account.createAutoJobOrLead === 'job' ? 'Booking confirmed' : 'Your request is pending business approval' }
                                </div>} 
                            </div>
                        }

                    </div>
                    <div className="summary-right">
                        <div className="date-and-time">
                            <div className="date">
                                <div>{eventDate.format("ddd")}, {eventDate.format("MMMM")} {eventDate.format("D")}</div>
                            </div>
                            <div className='time'>
                                {eventTime.format("h:mma")}-
                                {eventTime.add(accountSettings.slot_length,"hours").format("h:mma")}
                            </div>
                        </div>
                        {
                            props.form.fullAddress?.address.length > 0 &&
                            <div className="location-wrapper">
                                <div className="location-title">LOCATION</div>
                                <div className="address-and-map">
                                    <div className="address">
                                        <div className="full-address-part">
                                            {props.form.fullAddress.address}{" "}
                                            {props.form.fullAddress.city && <span className="full-address-part">{props.form.fullAddress.city}</span>}
                                            {props.form.fullAddress.state && <span className="full-address-part">, {props.form.fullAddress.state}</span>}
                                            {props.form.fullAddress.zipcode && <span className="full-address-part">, {props.form.fullAddress.zipcode}</span>}
                                        </div>
                                    </div>
                                    <div className="map-wrapper">
                                        <GoogleMaps
                                            address={`${props.form.fullAddress.address} ${props.form.fullAddress.city} ${props.form.fullAddress.state} ${props.form.fullAddress.zipcode}`}
                                            containerStyle={mapContainerStyle}
                                        />
                                    </div>
                                </div>
                            </div>
                        }


                    </div>
                </div>
            </div>
            {renderContact()}
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        account: state.account,
        services: state.services,
        form: state.form,
        servicesTotalAmount: state.servicesTotals.totalAmount,
        numOfServices: state.servicesTotals.numOfServices,
        totalTax: state.servicesTotals.totalTax,
        serviceFee: state.account.paymentSettings.serviceFeeSettings ?? null
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SuccessPage);