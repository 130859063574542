import React from "react";
import { useSelector } from "react-redux";
import ItemsPane from '../ItemsPane/ItemsPane';
import shoppingCart from "@assets/images/ShoppingCart.svg";
import styles from './Cart.module.scss';

const Cart = ({ enabledServices, goBack, showItemsPane, openItemsPane }) => {

    const servicesTotals = useSelector(state => state.servicesTotals);
    const hasItems = servicesTotals.numOfServices > 0 ? styles.withItems : "";

    if (!enabledServices) return null;
    return (
        <>
            <div className={`${styles.cart} ${hasItems}`} onClick={() => openItemsPane(!showItemsPane)}>
                {
                    servicesTotals.numOfServices > 0 &&
                    <div className={styles.counter}>
                        {servicesTotals.numOfServices}
                    </div>
                }
                <img src={shoppingCart} alt="cart" />
            </div>
            <ItemsPane
                isOpen={showItemsPane}
                close={() => openItemsPane(false)}
                goBack={goBack}
            />
        </>
    )
}

export default Cart;