import React from "react";
import styles from "./Button.module.scss"
import { CircularProgress } from "@material-ui/core";
const Button = ({ text, clickHandler, className, isLoading, iconLeft, iconRight, imgIconLeft, imgIconRight }) => {

    const click = () => {
        if (!isLoading) clickHandler();
    }

    return (
        <div
            onClick={click}
            className={`${styles.button} ${className}`}
        >
            {iconLeft && <i className={iconLeft} />}
            {imgIconLeft && <img src={imgIconLeft} alt="icon" />}
            {isLoading && <span className={styles.loading}><CircularProgress color="inherit" size={12} /></span>}
            <span>{text}</span>
            {iconRight && <i className={iconRight} />}
            {imgIconRight && <img src={imgIconRight} alt="icon" />}
        </div>
    )
}

export default Button;