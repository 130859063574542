import React from "react";
import moment from "moment";
import { updateForm } from "@actions/formActions"
import { useDispatch, useSelector } from "react-redux";
import styles from "../DateSelect.module.scss";

const TimeSlots = ({
    availableSlots,
    selected,
    slotLength,
    goToNextStep
}) => {

    const dispatch = useDispatch();
    const form = useSelector(state => state.form);
    const selectSlot = (slot) => {
        dispatch(updateForm({ type: 'time', data: slot }))
        goToNextStep()
    }

    const NoSlots = () => {
        return (
            <div className={styles.noSlots}>
                There are no available appointments for this date.<br />
                Please choose another date and try again
            </div>
        )
    }
    
    const SelectDate = () => {
        return (
            <div className={styles.chooseDateContainer}>
                <div className={styles.title}>Choose a date to see available time slots</div>
            </div>
        );
    }

    if (!selected) return <SelectDate />;

    return (
        <div className={styles.timeSelect}>
            <div className={styles.title}>Select a visit time</div>
            {!availableSlots.length && <NoSlots />}
            <div className={styles.slots}>
                {
                    availableSlots.map((slot, index) => {
                        const current = form.time === slot ? styles.selected : null;
                        return (
                            <div
                                className={`${styles.slot} ${current}`}
                                key={index}
                                onClick={() => selectSlot(slot)}
                            >
                                {moment(selected).startOf('day').add(slot, "hours").format('h:mma')}-
                                {moment(selected).startOf('day').add((slot + slotLength), "hours").format('h:mma')}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default TimeSlots;